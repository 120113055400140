import { Document, IsQuestion } from "../../model/Document";
import { Total } from "../../model/Total";
import { Section } from "../../model/Section";
import { Question } from "../../model/Question";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnswerCategory } from "../../model/AnswerCategory";
import { getScore } from "../../components/helpers/helperMethods";

export interface UpdateAnswerState {
    id: number;
    answer: string | boolean | (string & Date) | (string & string[]);
    score?:number | undefined;
}

export interface UpdateNoteState {
    id: number;
    noteText: string;
}

export interface UpdateDisciplineState {
    discipline: string;
    checked: boolean;
}

export interface UpdateCompletingUserState {
    completingUser: string;
}

const initialState = {} as Document;

export const documentSlice = createSlice({
    name: "updateDocument",
    initialState,
    reducers: {
        updateAnswer: (state, action: PayloadAction<UpdateAnswerState>) => {
            let questionState = getQuestionState(state, action.payload.id);
            if (!questionState) {
                return;
            }
            questionState.answer = action.payload.answer;
            if (questionState.kind === "select") questionState.score = action.payload.score ?? undefined;
        },

        updateNote: (state, action: PayloadAction<UpdateNoteState>) => {
            //kind, comment, question
            let questionState = getQuestionState(state, action.payload.id);
            if (!questionState) {
                return;
            }
            questionState.note = action.payload.noteText;
        },

        updateDocument: (state, action: PayloadAction<Document>) => {
            return action.payload;
        },
        updateDocumentDiscipline: (state, action: PayloadAction<UpdateDisciplineState>) => {
            if (!state?.disciplines) {
                state.disciplines = { PT: false, OT: false, ST: false };
            }
            state.disciplines[action.payload.discipline] = action.payload.checked;
        },
        updateCompletingUser: (state, action: PayloadAction<UpdateCompletingUserState>) => {
            state.completingUser = action.payload.completingUser;
        }
    }
});

function getQuestionState(
    document: Document,
    id: number
): Question | undefined {
    let foundQuestion: Question | undefined;
    const traverseSection = (section: Section, id: number) => {
        if (foundQuestion) {
            return;
        }
        if (section?.parts) {
            for (let part of section.parts) {
                if ((part as Section)?.parts) {
                    traverseSection(part as Section, id);
                } else if (IsQuestion(part) && part.id === id) {
                    foundQuestion = part;
                }
            }
        }
    };

    traverseSection({ parts: document.sections } as Section, id);
    return foundQuestion;
}


export function getSectionState(
    document: Document,
    id: number
): Section | undefined {
    let foundSection: Section | undefined;
    const traverseSection = (section: Section, id: number) => {
        if (foundSection) {
            return;
        }
        if (section.kind === "section" && section.id === id) {
            foundSection = section;
            return;
        }
        if (section?.parts) {
            for (let part of section.parts) {
                if ((part as Section)?.parts) {
                    traverseSection(part as Section, id);
                }
            }
        }
    };

    traverseSection({ parts: document.sections } as Section, id);
    return foundSection;
}

export function calculateSectionTotal(section: Section, totalType: string = "All") {
    let score = 0;
    

    const traverseSection = (section: Section) => {
        if (section?.parts) {
            for (let part of section.parts) {
                if (IsQuestion(part) && part.kind === "select") {

                    if (totalType == "All") score += getScore(part)
                    if (totalType == "Required" && part.required) score += getScore(part)
                    if (totalType == "Optional" && !part.required) score += getScore(part)
                }
                if ((part as Section)?.parts) {
                    traverseSection(part as Section);
                }
            }
        }
    };

    traverseSection(section);
    return score;
}

export function getRequiredFields(document: Document): Question[] {
    let unansweredRequiredQuestions = [] as Question[];
    const traverseSection = (section: Section) => {
        if (section?.parts) {
            for (let part of section.parts) {
                if (IsQuestion(part) && part.required
                    && (part.answer === undefined || part.answer === "")) {
                    unansweredRequiredQuestions.push(part);
                }
                if ((part as Section)?.parts) {
                    traverseSection(part as Section);
                }
            }
        }
    };

    traverseSection({ parts: document.sections } as Section);
    const areDisciplinesMarked = document?.disciplines && Object.values(document?.disciplines).some(Boolean);
    if (!areDisciplinesMarked) {
        unansweredRequiredQuestions.unshift({
            id: -1,
            question: 'Discipline'
        } as Question)
    }

    const isCompletingUserFilledIn = !!document?.completingUser

    if (!isCompletingUserFilledIn) {
        unansweredRequiredQuestions.unshift({
            id: 0,
            question: 'User completing screen'
        } as Question)
    }

    return unansweredRequiredQuestions;
}

export function findTotalScoreSection(
    document: Document
): Total | undefined {
    let totalScoreSection: Total;
    const traverseSection = (section: Section) => {
        if (section?.parts) {
            for (let part of section.parts) {
                if (part.kind === "totalScore" || part.kind === "PDPMScore") {
                    totalScoreSection = part;
                    return;
                }
                if ((part as Section)?.parts) {
                    traverseSection(part as Section);
                }
            }
        }
    };

    traverseSection({ parts: document.sections } as Section);
    return totalScoreSection;
}

export function areAllFieldsBlank(document: Document): boolean {
    let found: boolean = false;

    const traverseSection = (section: Section) => {
        if (section?.parts) {

            for (let part of section.parts) {
                if (IsQuestion(part)
                    && (part.answer !== undefined && part.answer !== "")) {
                    found = true;
                    break;
                }
                if ((part as Section)?.parts) {
                    traverseSection(part as Section);
                    if (found) {
                        break;
                    }
                }
            }
        }
    };

    traverseSection({ parts: document.sections } as Section);

    const areDisciplinesMarked = document?.disciplines && Object.values(document?.disciplines).some(Boolean);
    if (areDisciplinesMarked) {
        found = true;
    }

    const isCompletingUserFilledIn = !!document?.completingUser

    if (isCompletingUserFilledIn) {
        found = true;
    }

    return !found;
}

export const { updateDocument, updateAnswer, updateNote, updateDocumentDiscipline, updateCompletingUser } =
    documentSlice.actions;
export default documentSlice.reducer;
