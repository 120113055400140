import React, { useEffect, useRef, useState } from "react";
import { Document } from "../model/Document";
import { SourceDocument } from "../model/SourceDocument";
import { Section } from "../model/Section";
import { Question } from "../model/Question";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import store from "../store/configureStore";
import {
    findTotalScoreSection,
    getRequiredFields,
    updateDocument,
} from "../store/slices/documentSlice";
import { helloTherapyScreensApi } from "../repository/therapyScreensRepository";
import { PatientInfoHeader } from "./mainPage/PatientInfoHeader";
import { DocumentScreenTable } from "./mainPage/documentScreenTable/DocumentScreenTable";
import { DocumentDialog } from "./mainPage/document/DocumentDialog";
import * as documentApiHelper from "../repository/document/documentApiHelper";
import * as sourceDocumentApiHelper from "../repository/sourceDocument/sourceDocumentApiHelper";
import { CreateDocumentDialog } from "./mainPage/document/Dialogs/CreateDocumentDialog";
import { CssBaseline } from "@mui/material";
import { AddButtonHeader } from "./mainPage/AddButtonHeader";
import { RequiredFieldsConfirmationDialog } from "./mainPage/document/Dialogs/RequiredFieldsConfirmationDialog";
import { documentSelector } from "../store/selectors/documentSelectors";
import { useTotal } from "../hooks/useTotal";
import { getFormResult } from "./helpers/formResults/formResultCalculator";
import { useConfirmationDialog } from "../hooks/useConfirmationDialog";
import { Label } from "./common/Label/Label";
import { DisplayVersion } from "./common/DisplayVersion/DisplayVersion";
import { GetDisplayVersion } from "../repository/displayVersionRepository";
import { LoadingComponent } from "./common/Loader/LoadingComponent";
import classes from "./applicationComponent.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "./global";
import useInitializeWindow from "../hooks/useInitializeWindow";
import usePersistToken from "../hooks/usePersistToken";
import { InitMessage } from "../com_integration/models/InitMessage";

const ApplicationComponent: React.FC = () => {
    useInitializeWindow();
    usePersistToken();

    const [initMessage, setInitMessage] = useState<InitMessage>();

    const [sourceDocuments, setSourceDocuments] = useState<SourceDocument[]>();
    const [patientScreens, setPatientScreens] = useState<Document[]>();

    const [unansweredRequiredQuestions, setUnansweredRequiredQuestions] =
        useState<Question[]>();

    const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);
    const [isSavingDocumentDialog, setIsSavingDocumentDialog] = useState(false);
    const [isCreateDocumentDialogOpen, setIsCreateDocumentDialogOpen] =
        useState(false);
    const [isRequiredFieldDialogOpen, setIsRequiredFieldDialogOpen] =
        useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalParentId, setTotalParentId] = useState<number>(0);
    const [displayVersion, setDisplayVersion] = useState("");

    const documentToDelete = useRef<Document | null>(null);

    const confirmationDialog = useConfirmationDialog();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let selectorDocument:Document = useAppSelector(documentSelector);

    AppContext.isInPrintPreview = false;
    AppContext.currentAnswerCategories = selectorDocument.answerCategories;
    let score = useTotal(totalParentId);

    useEffect(() => {
        (window as any).initializeAppComponent = (data: InitMessage) => {
            setInitMessage(data);
            helloTherapyScreensApi();
            getInitialData(data);
            getDisplayVersion();
        };
    }, []);

    useEffect(() => {
        let data = location?.state?.initMessage;

        if (data) {
            setInitMessage(data);
            helloTherapyScreensApi();
            getInitialData(data);
        }
    }, [location]);

    async function getInitialData(data: InitMessage) {
        await fetchSourceDocuments(data.orgId);
        await fetchInitialPatientDocuments(data);
    }

    async function fetchInitialPatientDocuments(data: InitMessage) {
        setPatientScreens(
            await documentApiHelper.GetAllDocumentsByPatientId(
                data?.patient?.patientId?.toString() || "",
                data?.facility?.facilityId?.toString() || "",
                data?.orgId
            )
        );
        setIsLoading(false);
    }

    async function fetchSourceDocuments(orgId: string) {
        let sources = await sourceDocumentApiHelper.GetSourceDocumentsByOrgId(orgId);
        let sortedSources = sources?.sort(function (a, b) {
            return a.documentTemplate?.screenReason?.toLowerCase().localeCompare(b.documentTemplate?.screenReason?.toLowerCase());
        });

        setSourceDocuments(sortedSources);
    }

    async function fetchPatientDocuments() {
        setPatientScreens(
            await documentApiHelper.GetAllDocumentsByPatientId(
                initMessage?.patient?.patientId?.toString() || "",
                initMessage?.facility?.facilityId?.toString() || "",
                initMessage?.orgId
            )
        );
    }

    function handleDeleteDocument(id: string) {
        let document = patientScreens?.find((document) => document.id === id);
        documentToDelete.current = document;
        confirmationDialog.show({
            message: `Are you sure you want to delete ${documentToDelete.current?.screenReason} for ${documentToDelete.current?.screenDate}? You will not be able to undo this action!`,
            title: "Please Confirm",
            onCancel: () => {
                confirmationDialog.hide();
                documentToDelete.current = null;
            },
            onConfirm: deleteDocument,
            cancelText: "No",
            confirmText: "Yes",
        });
    }

    async function deleteDocument() {
        if (documentToDelete.current) {
            await documentApiHelper.DeleteDocument(documentToDelete.current.id);
            await fetchPatientDocuments();
            documentToDelete.current = null;
            confirmationDialog.hide();
        }
    }

    function showDocument(id: string) {
        let document = patientScreens?.find((document) => document.id === id);

        if (document) {
            dispatch(updateDocument(document));
            updateTotalParentId(document);
            setIsDocumentDialogOpen(true);
        }
    }

    function updateTotalParentId(document: Document) {
        let totalSection = findTotalScoreSection(document);
        if (totalSection) {
            setTotalParentId(totalSection.parentId);
        } else {
            var rootsection: Section;
            // use first section of document root instead
            for (let section of document.sections) {
                if (section.kind === "section") {
                    rootsection = section as Section;
                    break;
                }
            }
            setTotalParentId(rootsection.id);
        }
    }

    async function getDisplayVersion() {
        setDisplayVersion(await GetDisplayVersion());
    }

    function showRequiredFieldConfirmation(requiredFields: Question[]) {
        setUnansweredRequiredQuestions(requiredFields);
        setIsRequiredFieldDialogOpen(true);
    }

    async function persistData(document: Document) {
        if (document?.id === "") {
            await documentApiHelper.CreateDocument();
        } else {
            await documentApiHelper.UpdateDocument();
        }
    }

    function updateScreenResult(result: string, resultJSON?: any) {
        let documentToUpdate = { ...store.getState().updateDocument };

        documentToUpdate.screenResult = result;
        documentToUpdate.screenResultJSON = resultJSON;

        dispatch(updateDocument(documentToUpdate));
    }

    function updateDocumentContext() {
        let document = selectorDocument;

        let documentToUpdate = { ...store.getState().updateDocument };

        documentToUpdate.patient = initMessage.patient;
        documentToUpdate.facility = initMessage.facility;

        documentToUpdate.startOfCareDates = {
            otDate: getStartOfCareDate(initMessage.patient?.otTrackStartDate, document.requestedDate),
            ptDate: getStartOfCareDate(initMessage.patient?.ptTrackStartDate, document.requestedDate),
            stDate: getStartOfCareDate(initMessage.patient?.stTrackStartDate, document.requestedDate)
        };

        dispatch(updateDocument(documentToUpdate));
    }

    function getStartOfCareDate(startDateStr: string, requestDateStr: string) {
        let lastAdmitDate = new Date(initMessage.patient.admissionDate);
        let startDate = new Date(startDateStr);
        let requestDate = new Date(requestDateStr);

        if (startDate >= lastAdmitDate && startDate >= requestDate)
            return startDate?.toLocaleDateString();;
        return "";
    }

    async function handleSaveEditDocument(
        checkRequiredFields: boolean = true,
        showConfirmationMessage: boolean = true
    ) {
        let document:Document = selectorDocument;
        
        setIsSavingDocumentDialog(true);


        let message =  `${document?.screenReason} Saved!`;
        try{
            if (checkRequiredFields) {
                let requiredFields = getRequiredFields(document);
    
                if (requiredFields.length > 0) {
                    setIsSavingDocumentDialog(false);
                    showRequiredFieldConfirmation(requiredFields);
                    return;
                }
    
                await setScreenResult();
            }
    
            await persistData(document); //this can fail if the document db schema isn't up to date

        }catch (error){
            message =  `${document?.screenReason} Error While Saving!`;
            console.error('Error occurred:', (error as Error).message);
        }
        setIsDocumentDialogOpen(false);
        setIsSavingDocumentDialog(false);

        if (showConfirmationMessage) {
            confirmationDialog.show({
                message:message,
                title: "Saved successfully",
                onConfirm: async () => {
                    confirmationDialog.hide();
                    await fetchPatientDocuments();
                },
                confirmText: "Ok",
            });
        }

        await fetchPatientDocuments();
    }

    async function setScreenResult() {
        let document:Document = selectorDocument;

        let result = await getFormResult(score, document);

        if (result == null || typeof result === "string") {
            updateScreenResult(result ?? "Complete");
        } else {
            updateScreenResult("Complete", result);
        }
    }

    function handlePrintDocument(id: string) {
        let documentToPrint = patientScreens?.find(
            (document) => document.id === id
        );
        dispatch(updateDocument(documentToPrint));

        navigate("/preview", {
            state: {
                patientName: initMessage?.patient?.patientName,
                initMessage: initMessage,
            },
        });
    }

    function handleCancelEditDocument() {
        setIsDocumentDialogOpen(false);
    }

    function handleCancelCreateDocument() {
        setIsCreateDocumentDialogOpen(false);
    }

    function handleConfirmRequiredFields() {
        setIsSavingDocumentDialog(true);
        updateScreenResult("Incomplete");
        setIsRequiredFieldDialogOpen(false);
        handleSaveEditDocument(false);
    }

    function handleCancelRequiredFields() {
        setIsRequiredFieldDialogOpen(false);
    }

    async function handleCreateDocument(documentToCreate: Document) {
        if (documentToCreate) {
            dispatch(updateDocument(documentToCreate));
            updateTotalParentId(documentToCreate);
            setIsCreateDocumentDialogOpen(false);
            setIsDocumentDialogOpen(true);
        }
    }

    async function updateScreenReasonChanged(updatedDocument: Document) {
        dispatch(updateDocument(updatedDocument));
        handleSaveEditDocument(false, false);
    }

    function Application() {
        if (patientScreens && patientScreens?.length > 0) {
            return (
                <DocumentScreenTable
                    showDocument={showDocument}
                    deleteDocument={handleDeleteDocument}
                    screens={patientScreens}
                    sourceDocuments={sourceDocuments}
                    updateScreenReasonChanged={updateScreenReasonChanged}
                    printDocument={handlePrintDocument}
                    userRights={initMessage?.rights}
                />
            );
        } else {
            return (

                <Label styleName={classes["emptyItems"]}>
                    no items to display
                </Label>
            );
        }
    }
    return (
        <>
            <div>
                <CssBaseline />
                <PatientInfoHeader initMessage={initMessage} />
                {initMessage?.rights?.add && (
                    <AddButtonHeader
                        setIsCreateDocumentDialogOpen={
                            setIsCreateDocumentDialogOpen
                        }
                    />
                )}

                <CreateDocumentDialog
                    sourceDocuments={sourceDocuments!}
                    isOpen={isCreateDocumentDialogOpen}
                    handleCreateDocument={handleCreateDocument}
                    handleCancel={handleCancelCreateDocument}
                    patientId={initMessage?.patient?.patientId}
                    facilityId={initMessage?.facility?.facilityId}
                    facilityName={initMessage?.facility?.facilityName}
                    orgId={initMessage?.orgId}
                    patient={initMessage?.patient}
                    facility={initMessage?.facility}
                />

                <DocumentDialog
                    document={selectorDocument!}
                    isOpen={isDocumentDialogOpen}
                    handleSaveEditDocument={handleSaveEditDocument}
                    handleCancelEditDocument={handleCancelEditDocument}
                    handlePrintDocument={handleCancelEditDocument}
                    patientName={initMessage?.patient?.patientName}
                    previewMode={false}
                    isSaving={isSavingDocumentDialog}
                />

                <RequiredFieldsConfirmationDialog
                    unansweredRequiredQuestions={unansweredRequiredQuestions}
                    isOpen={isRequiredFieldDialogOpen}
                    handleCancel={handleCancelRequiredFields}
                    handleConfirm={handleConfirmRequiredFields}
                    isSaving={isSavingDocumentDialog}
                />

                {confirmationDialog.component}

                {isLoading ? (

                    <LoadingComponent />

                ) : (
                    <Application />

                )}

                <DisplayVersion version={displayVersion} />

            </div>
        </>
    );
};

export default ApplicationComponent;
